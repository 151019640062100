/* display background color black on navbar scroll */
.navbarScroll.navbarDark {
    background-color: black;
}
.navbar{
    color: black;
    background: darkslateblue;
}


/* hero background image */
.bgimage {
    height:100vh;
    background: url('../images/heroImage.jpg');
    background-size:cover;
    position:relative;
}
/* text css above hero image*/
.hero_title {
    font-size: 4.5rem;
}
.hero_desc {
    font-size: 2rem;
}
.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: whitesmoke;
    font-size:60px;
}

/* products section image css */
.products-image-wrapper{
    margin: auto;
    width: 20%; /*set width as you need it*/
    height: 20%;
    padding: 5px;
    background: white;  /* just added bg to check the alignment*/
}

/* quotes section image css */
#quotes{
    background: lightgray;
}

/* features section image css */
#features{
    background: lightgray;
}
.feature-box{
    clear: both;
}
.feature-box-icon{
    background: whitesmoke;
    border-radius: 35px;
    color: #52AD81;
    display: inline-block;
    float: left;
    height: 20px;
    line-height: 35px;
    margin-right: 10px;
    position: relative;
    text-align: center;
    width: 20px;
}

.technologies-image-wrapper{
        margin: auto;
        width: 80%; /*set width as you need it*/
        height: 80%;
        border: 3px solid whitesmoke; /* just added border to check the alignment you can adjust*/
        padding: 10px;
        background: white;  /* just added bg to check the alignment*/
        float: right!important;
}

/* about section image css */
#career{
    background-color: lightgray;
}
.quotes{
    font-style: italic;
    color:blueviolet;
}

/* about section image css */
.imageAboutPage {
    width: 100%;
}



/* social media icons styling */
.social-icons {
    font-size: 36px;
    cursor: pointer;
}
.fa-facebook:hover,.fa-instagram:hover,.fa-twitter:hover,.fa-linkedin:hover, .fa-twitch:hover {
    color: #008000;
}
.fab {
    color: whitesmoke;
}
/* footer styling */
#footer {
    background-color: darkslateblue;
    text-align: center;
    color: whitesmoke;
}

.fab-container{
    position:fixed;
    bottom:20px;
    right:20px;
    cursor:pointer; 
    }